<template>
  <q-dialog :model-value="$attrs.modelValue" persistent transition-show="flip-down" transition-hide="flip-up">
    <q-card>
      <q-card-section>
        <div class="text-h6">{{ title }}</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        {{ body }}
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="Yes" size="sm" color="red" v-close-popup @click="onYes" />
        <q-btn flat label="No" size="sm" color="primary" v-close-popup @click="onNo"/>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref } from '@vue/runtime-core'
export default {
  props: {
    title: {
      type: String,
      default: 'Confirmation'
    },
    body: {
      type: String,
      default: ''
    }
  },
  setup (props, ctx) {
    const onYes = () => {
      ctx.emit('onYes')
    }

    const onNo = () => {
      ctx.emit('onNo')
    }

    const dialog = ref(false)

    return {
      dialog,
      onYes,
      onNo
    }
  }
}
</script>
