import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: 'Home'
    },
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    children: [
      {
        path: ':id',
        name: 'Root',
        meta: {
          title: 'Home'
        },
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
      },
      {
        path: ':id/profile',
        name: 'Profile',
        meta: {
          title: 'Profile'
        },
        component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue')
      },
      {
        path: ':id/educations',
        name: 'Educations',
        meta: {
          title: 'Educations'
        },
        component: () => import(/* webpackChunkName: "educations" */ '../views/Educations.vue')
      },
      {
        path: ':id/works',
        name: 'Works',
        meta: {
          title: 'Works'
        },
        component: () => import(/* webpackChunkName: "works" */ '../views/Works.vue')
      },
      {
        path: ':id/skills',
        name: 'Skills',
        meta: {
          title: 'Skills'
        },
        component: () => import(/* webpackChunkName: "skills" */ '../views/Skills.vue')
      },
      {
        path: ':id/projects',
        name: 'Projects',
        meta: {
          title: 'Projects'
        },
        component: () => import(/* webpackChunkName: "projects" */ '../views/Projects.vue')
      },
      {
        path: ':id/certificates',
        name: 'Certificates',
        meta: {
          title: 'Certificates'
        },
        component: () => import(/* webpackChunkName: "certificates" */ '../views/Certificates.vue')
      },
      {
        path: ':id/contacts',
        name: 'Contacts',
        meta: {
          title: 'Contacts'
        },
        component: () => import(/* webpackChunkName: "contacts" */ '../views/Contacts.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const APP_TITLE = process.env.VUE_APP_TITLE ?? 'Resume Online'

router.beforeEach((to, from, next) => {
  document.title = `${APP_TITLE ? APP_TITLE + ' - ' : ''}${to.meta.title}`
  next()
})

export default router
