import { createStore } from 'vuex'
import api from '../api'

export default createStore({
  state: {
    profile: null,
    educations: [],
    works: [],
    projects: [],
    certificates: [],
    contacts: [],
    skills: [],
    options: null
  },
  getters: {
    ddContacts (state) {
      return state.options?.contacts?.sort((a, b) => a.text - b.text) ?? []
    },
    ddDegrees (state) {
      return state.options?.degrees.sort((a, b) => a.text - b.text) ?? []
    },
    ddMedias (state) {
      return state.options?.medias.sort((a, b) => a.text - b.text) ?? []
    },
    ddSkills (state) {
      return state.options?.skills.sort((a, b) => a.text - b.text) ?? []
    }
  },
  mutations: {
    setProfileFull (state, full) {
      state.profile = full?.profile
      state.educations = full?.educations
      state.works = full?.works
      state.projects = full?.projects
      state.certificates = full?.certificates
      state.contacts = full?.contacts
      state.skills = full?.skills
    },
    setOptions (state, options) {
      state.options = options
    }
  },
  actions: {
    async setProfileFull ({ commit, state }, { id, field }) {
      const res = await api.user.getDetail(id, field)

      commit('setProfileFull', res?.data)

      return Promise.resolve(res)
    },
    async setOptions ({ commit }, options) {
      const res = await api.options.gets()
      if (res?.success) {
        commit('setOptions', res?.data)
      }

      return Promise.resolve(res)
    }
  },
  modules: {
  }
})
