<template>
  <q-layout view="hHh Lpr lFf">
    <q-header elevated class="glossy">
      <q-toolbar>
        <q-btn
          v-if="false"
          flat
          dense
          round
          @click="leftDrawerOpen = !leftDrawerOpen"
          aria-label="Menu"
          icon="menu"
        />

        <q-toolbar-title class="cursor-pointer">
          {{ appTitle }}
        </q-toolbar-title>
      </q-toolbar>
    </q-header>

    <q-drawer
      v-if="false"
      v-model="leftDrawerOpen"
      show-if-above
      bordered
      class="bg-grey-2 q-pt-lg"
    >
      <q-list>
        <q-item
          v-for="(m, i) in menus"
          :key="'menu-' + i"
          clickable
          :active="selectedMenu === i"
          @click="$router.push(m.to); selectedMenu = i"
        >
          <q-item-section v-if="m.icon" avatar>
            <q-icon :name="m.icon" />
          </q-item-section>
          <q-item-section>
            <q-item-label>{{ m.title }}</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-drawer>

    <q-page-container class="doc-container">
      <router-view class="q-pa-lg" />
    </q-page-container>
  </q-layout>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  name: 'App',
  setup () {
    const router = useRouter()
    const store = useStore()

    const selectedMenu = ref(null)

    const menus = ref([{
      title: 'Profile',
      icon: 'manage_accounts',
      to: '/profile'
    }, {
      title: 'Educations',
      icon: 'school',
      to: '/educations'
    }, {
      title: 'Work',
      icon: 'engineering',
      to: '/works'
    }, {
      title: 'Skills',
      icon: 'stars',
      to: '/skills'
    }, {
      title: 'Projects',
      icon: 'auto_stories',
      to: '/projects'
    }, {
      title: 'Certificates',
      icon: 'card_membership',
      to: '/certificates'
    }, {
      title: 'Contacts',
      icon: 'contacts',
      to: '/contacts'
    }])

    onMounted(async () => {
      store.dispatch('setOptions')
    })

    const logout = () => {
      localStorage.removeItem('token')
      location.href = '/'
    }

    const profile = computed(() => store.state.profile)

    const goHome = () => {
      router.push('/dashboard')
    }

    const appTitle = process.env.VUE_APP_TITLE

    return {
      leftDrawerOpen: ref(false),
      profile,
      logout,
      menus,
      goHome,
      selectedMenu,
      appTitle
    }
  }
}
</script>
