import { ajax, ajaxFail } from '../utils/functions'

const prefix = 'user'

export default {
  async getDetail (id, field) {
    let url = `/${prefix}/${id}`

    if (field) {
      url += `/${field}`
    }

    try {
      const res = await ajax().get(url)

      return Promise.resolve(res.data)
    } catch (error) {
      return Promise.resolve(ajaxFail(error))
    }
  }
}
