import moment from 'moment'
import axios from 'axios'

export function ajax () {
  const url = process.env.VUE_APP_API_URL
  const ajax = axios.create({
    baseURL: url
  })

  ajax.interceptors.request.use((config) => {
    if (localStorage.getItem('token')) {
      config.headers = {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    }

    return config
  })

  return ajax
}

export function ajaxFail (error) {
  return {
    success: false,
    data: null,
    message: ajaxErrorParse(error)
  }
}

export function ajaxErrorParse (error) {
  return (error?.response?.data?.message || error?.response?.statusText || error?.message) ?? 'Unknown error occured.'
}

export function formatDate (d, format) {
  return moment(d).format(format)
}

export function randomString (length = 32) {
  var text = ''
  var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (var i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }

  return text
}

export function isSubPropertyExists (obj, path) {
  var args = path.split('.')

  for (var i = 0; i < args.length; i++) {
    // eslint-disable-next-line no-prototype-builtins
    if (!obj || !obj.hasOwnProperty(args[i])) {
      return false
    }
    obj = obj[args[i]]
  }

  return true
}

export function limitText (str, limit, overflow = false) {
  if (limit > 0) {
    if (str.length > limit) str = str.substring(0, limit)
  }

  return str + (overflow ? (str.length > limit ? '...' : '') : '')
}

export function formatMoney (amount, decimalCount = 2, thousands = '.', decimal = ',') {
  try {
    decimalCount = Math.abs(decimalCount)
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount

    const negativeSign = amount < 0 ? '-' : ''

    const i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString()
    const j = (i.length > 3) ? i.length % 3 : 0

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '')
  } catch (e) {
    console.log(e)
  }
}

export function sleep (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function validationRules (field, value) {
  return {
    required: (value && value.length > 0) || `${field} is required`
  }
}

export function capitalize (str) {
  return str && str[0].toUpperCase() + str.slice(1)
}
