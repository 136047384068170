import { ajax, ajaxFail } from '../utils/functions'

export default {
  async gets () {
    try {
      const res = await ajax().get('/options')

      return Promise.resolve(res.data)
    } catch (error) {
      return Promise.resolve(ajaxFail(error))
    }
  }
}
